/*
 * @Author: your name
 * @Date: 2021-09-26 10:56:38
 * @LastEditTime: 2021-09-26 14:41:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \zhfd-web-sale\src\api\help\index.js
 */
import request from '@/utils/request'

// 交易记录列表
export function getComProblem(data) {
  return request({
    url: '/api/sale/platfcfg/commproblem/page',
    method: 'POST',
    data
  })
}

// 获取常见问题详细信息
export function getProblemDetail(data) {
  return request({
    url: '/api/sale/platfcfg/commproblem/find',
    method: 'POST',
    data
  })
}

// 意见反馈
export function feedBack(data) {
  return request({
    url: '/api/system/custservice/complaint/insert',
    method: 'POST',
    data
  })
}